import resume from './resume.pdf'

import {Page, Document} from 'react-pdf'
export default function Resume(){
return (<div className='resumeWrapper' >
        <Document
        className='resume'
        file={resume} 
        >
    <Page 
     pageNumber={1}
    width={'1024'} />
    </Document>
    </div>
)
}
