import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import {PerspectiveCamera} from '@react-three/drei'
import Experience from './Experience.jsx'
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();
const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <Canvas
        className="r3f"
        // camera={ {
        //     fov: 45,
        //     near: 0.1,
        //     far: 2000,
        //     position: [ -3, 1.5, 4 ]
        // } }
    >
        <PerspectiveCamera 
        
        position={[ -3, 1.5, 4 ]}
    />
        <Experience />
    </Canvas>
)
