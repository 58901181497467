import {Environment, OrbitControls, PresentationControls, useGLTF, Float, ContactShadows, Html, Text } from '@react-three/drei'
import {useState,useEffect} from 'react'
import Resume from './Resume.jsx'
import laptop from './model.gltf'

export default function Experience(){
const [content, setContent] = useState({})
useEffect(()=>{
    setContent(
        <Resume />
    )
    }
,[])

    const computer = useGLTF(laptop)
    return <>
        <color args={['#241a1a']} attach="background" />
        <PresentationControls
            global 
            rotation={[0.13, 0.1,0]}
            polar={[ -0.4, 0.2]}
            azimuth={[-1, 0.75]}
            config={{mass:2, tension:400}}
            /* snap={{mass:4,tension:400}} */
        >
        <Float
            rotationIntensity={0.1}
        >
        <rectAreaLight 
            width={2.5}
            height={1.65}
            intensity={50}
            color={'#FFFFFF'}
            rotation={[-0.1, Math.PI,0]}
            position={[0,0.55, -1.15]}

        />
        <primitive 
            object={computer.scene} 
            position-y={- 1.2}    
            position-x={-1}
            position-z={2}
            rotation-y={-0.2}
            rotation-x={-0.1}
        >
        <Html
            transform
            wrapperClass="htmlScreen"
            distanceFactor={1.17}
            position={[ 0, 1.56, -1.4]}
            rotation-x={ - 0.256}
        >
        {content}
        </Html>
        </primitive>
        {/* <Text */}
        {/*     font="./bangers-v20-latin-regular.woff" */}
        {/*     fontsize={0.8} */}
        {/*     position={[2,0.75, 2]} */}
        {/*     rotation-y={ - 1.25} */}
        {/* > */}
        {/*     ABHI */}
        {/* </Text> */}
        </Float>
        <Environment preset="city" />
       </PresentationControls> 
        <ContactShadows 
            position-y={-1.4} 
            opacity={0.4}
            scale={5}
            blur={2.4}
        />
    </>
}
